<template>
  <div id="app" class="bg-base-200">
    <meta name="referrer" content="no-referrer">
    <magic-menu v-if="$route.name !== 'login'"></magic-menu>
    <div v-else style="height: 100%;">
      <login></login>
    </div>
  </div>
</template>

<script>
import magicMenu from '@/components/base/magicMenu'
import Login from '@/views/user/login'

export default {
  name: 'app',
  components: {
    magicMenu,
    Login
  },
  data() {
    return {
      menuList: []
    }
  },
  created() {
    this.getHeight()
  },
  methods: {
    getHeight() {
      const headerHeight = 60
      if (window.innerHeight) {
        this.winHeight = window.screen.availHeight - (window.outerHeight - window.innerHeight)
      }
      this.winHeight = this.winHeight - headerHeight + 'px'
    }
  }
}
</script>
<style>
.bk-sideslider-closer {
  background-color: #2b3340 !important;
}

.bk-sideslider-title {
  padding: 0 0 0 0 !important;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, .4)
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: rgba(255, 255, 255, .07)
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(11, 54, 106, .52);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5)
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(255, 255, 255, .07)
}

.h-screen-header {
  height: calc(100vh - 85px);
}
</style>
