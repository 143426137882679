<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
                <a v-for="{ value, text } in options" :key="value"
                    :class="{ 'tab': true, 'tab-active': sortValue === value }">
                    <router-link :to="{ 'path': '/playlists/' + value }">
                        {{ text }}
                    </router-link>
                </a>
            </div>
        </div>
        <Tiles v-if="playlist.length > 0">
            <Tile
                v-for="item in playlist" :key="item.id"
                :image="item.image"
                :to="{ name: 'playlist', params: { id: item.id } }"
                :title="item.name">
                <template #text>
                    <router-link :to="{ name: 'playlist', params: { id: item.id } }">
                        <strong>{{ item.trackCount }}</strong> 歌曲
                    </router-link>
                </template>
                <template #play>
                    <div class="absolute top-[43%] z-10 left-[39%] play-icon cursor-pointer" @click="playNow(item.id)">
                        <svg class="fill-purple-800 w-12 h-12 hover:fill-purple-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                    </div>
                </template>
            </Tile>
        </Tiles>
    </div>
</template>
<script>
    import Tiles from '@/components/library/Tiles'
    import Tile from '@/components/library/Tile'
    export default {
        components: {
            Tiles, Tile
        },
        props: {
        },
        data() {
            return {
                options: [
                    {text: '最近添加', value: 'recently-added'},
                    {text: 'A-Z排序', value: 'a-z'}
                ],
                playlist: [],
                showAddModal: false
            }
        },
        computed: {
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                async handler() {
                    this.playlist = await this.$api.subsonic.getPlaylists(this.sortValue)
                }
            }
        },
        async created() {
            this.playlist = await this.$api.subsonic.getPlaylists(this.sortValue)
        },
        methods: {
            async playNow(id) {
                const playlist = await this.$api.subsonic.getPlaylist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: playlist.tracks
                })
            }
        }
    }
</script>
