<template>
    <div class="bg-base-200 h-screen-header rounded-xl p-2">
        <div class="flex flex-col">
            <h1 class="mt-2 ml-2 text-2xl">
                正在播放
            </h1>
            <div class="mt-2 ml-2">
                <button variant="link" class="btn btn-primary btn-sm mr-1" @click="shuffle">
                    打乱
                </button>
                <button variant="link" @click="clear" class="btn btn-neutral btn-sm mr-1">
                    清空
                </button>
                <button variant="link" @click="playNow('random')" class="btn btn-neutral btn-sm mr-1">
                    随机播放
                </button>
            </div>
        </div>
        <div class="rounded-xl mt-4 overflow-y-auto h-[86.3%]" v-if="tracks.length > 0">
            <TrackList :tracks="tracks" no-artist />
        </div>
        <div v-else>
            <div class="flex justify-center mt-24">
                <div class="flex flex-col items-center text-gray-500">
                    <img src="/static/dist/img/play_music.svg" alt="Album" class=" h-64 z-1 mb-4" />
                    <div>暂无播放</div>
                    <div>
                        <router-link :to="{ 'path': '/albums/' + 'recently-added' }" class="underline underline-offset-2">
                            去聆听音乐吧!
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import TrackList from '@/views/library/track/TrackList'

    export default {
        components: {
            TrackList
        },
        computed: {
            isPlaying() {
                return this.$store.state.common.isPlaying
            },
            tracks() {
                return this.$store.state.common.queue
            },
            queueIndex() {
                return this.$store.state.common.queueIndex
            }
        },
        created() {
        },
        methods: {
            play(index) {
                if (index === this.queueIndex) {
                    return this.$store.dispatch('playPause')
                }
                return this.$store.dispatch('playTrackListIndex', {index})
            },
            dragstart(id, event) {
                event.dataTransfer.setData('application/x-track-id', id)
            },
            remove(idx) {
                return this.$store.commit('removeFromQueue', idx)
            },
            clear() {
                return this.$store.commit('clearQueue')
            },
            shuffle() {
                return this.$store.commit('shuffleQueue')
            },
            formatDuration(value) {
                if (!isFinite(value)) {
                    return '∞'
                }
                const minutes = Math.floor(value / 60)
                const seconds = Math.floor(value % 60)
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
            },
            async playNow(id) {
                const playlist = await this.$api.subsonic.getPlaylist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: playlist.tracks
                })
            }
        }
    }
</script>
