import { render, staticRenderFns } from "./resource.vue?vue&type=template&id=44ef6504&scoped=true"
import script from "./resource.vue?vue&type=script&lang=js"
export * from "./resource.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ef6504",
  null
  
)

export default component.exports